import React, { useState, useContext } from 'react'
import { FormGroup, Input, Label, Row } from 'reactstrap'
import MotionContainer from '../Components/motionContainer';
import { ThemeParent } from '../Context/ThemeContext';
import emailjs from '@emailjs/browser';
import { emailJsInfo } from '../Data/emailjsConfig';
export default function Contact() {

  const { theme } = useContext(ThemeParent);
  const [contactmeForm, setContactmeForm] = useState({
    name: '',
    email: '',
    message: ''
  })


  const [buttonMessage, setButtonMessage] = useState('Send Message')
  const handleChange = (e: any) => {
    setContactmeForm({
      ...contactmeForm,
      [e.target.name]: e.target.value
    })
  }

  const inputChecker = (): boolean => {
    if (contactmeForm.name === '' || contactmeForm.email === '' || contactmeForm.message === '') {
      return false
    } else { return true }
  }


  const sendMail = (): void => {
    inputChecker() && emailjs.send(
      emailJsInfo.service_id,
      emailJsInfo.template_id,
      {
        from_name: contactmeForm.email,
        email_subject: " from personal site" + contactmeForm.email,
        message: contactmeForm.message,
        reply_to: "abrahamterfie4563@gmail.com",
      },
      emailJsInfo.public_key
    ).then((result) => {
      setButtonMessage('Message sent')
      return true
    }, (error) => {
      setButtonMessage('Message not sent')
      return false
    });
  }

  return (
    <div className='p-5 mt-5 mx-auto' style={theme.contactMeForm}>
      <div>
        <h6 className='text-start mb-3 fw-light fs-5' style={{ color: theme.text2 }}>
          if you have any questions or want to work with me, please feel free to contact me.
        </h6>
      </div>

      <FormGroup>
        <Label for="name">Name</Label>
        <Input
          type="text"
          name="name"
          id="name"
          placeholder="Enter your name"
          value={contactmeForm.name}
          onChange={handleChange}
          style={theme.input}
          valid={inputChecker()}
        />
      </FormGroup>
      <FormGroup>
        <Label for="email">Email</Label>
        <Input
          type="email"
          name="email"
          id="email"
          placeholder="Enter your email"
          value={contactmeForm.email}
          onChange={handleChange}
          style={theme.input}
          valid={inputChecker()}

        />
      </FormGroup>
      <FormGroup>
        <Label for="message">Message</Label>
        <Input

          type="textarea"
          name="message"
          id="message"
          placeholder="Enter your message"
          value={contactmeForm.message}
          onChange={handleChange}
          style={theme.input}
          rows={15}
          valid={inputChecker()}

        />
      </FormGroup>
      <MotionContainer>
        <Row onClick={sendMail}>
          <p
            style={theme.notActiveTab}
            className={` text-${inputChecker() ? "success" : "danger"
              }-emphasis bg-${inputChecker() ? "success" : "danger"
              }-subtle 
              }-subtle  fs-4 fw-normal  rounded-3 p-1 text-center`}>
            {buttonMessage}
          </p>
        </Row>
      </MotionContainer>

    </div>
  )
}
