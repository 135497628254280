import React from 'react'
import { motion } from 'framer-motion'
export default function MotionContainer(
    { children, }: { children: any }
) {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 1 }}
            whileHover={{ scale: 1.08 }}
            whileTap={{ scale: 0.95 }}
            transition={{ type: 'spring', stiffness: 400, damping: 50 }}
            style={{ cursor: 'pointer'  }}


        >
            {children}
        </motion.div>

    )
}
