
import * as images from '../Assets/index'

export const myData = [
    {
        id: 1,
        projectName: "Sparta ERP / Iwork Technologies PLC",
        description: 'A multi purpose enterprise resource planning (ERP) software  custom made for companies to manage their business operations and customer relationships on a single integrated platform. It is a web based application integrated with POS (Point of Sale) and CRM (Customer Relationship Management) modules. by default includes Finance Management, Human Resource Management, Inventory Management, Sales Management, Purchase Management, Production Management, Project Management, and Customer Relationship Management (CRM) modules. and added more modules like POS, Payroll, and Warehouse Management System (WMS) Quality control, Maintenance Management, and Asset Management and more as per client requirements.',
        stacksused: ["React", "Redux", "React Router", "React Bootstrap", "React PDF", "React Hook Form", "Rest API "],
        color: "primary",
        images: [images.iwork1, images.iwork2, images.iwork3],
        // github: "github.com",
        liveLink: "https://www.iworkplc.com/",

    },
    {
        id: 2,
        projectName: "Debut ",
        description: "Debut is a digital platform aimed at helping founders mobilize their village (family, friends and extended network) to help launch their startup or project. Think “baby-shower for your startup”.",
        stacksused: ["React", "Redux", "Auth0", "React Router", "React Spring", "GraphQL", "Apollo", "mongoDB", "Node", "Bootsrap", "Emailjs", "cloudinary", "Stripe"],
        color: "secondary",
        images: [images.debut1, images.debut2, images.debut3],
        github: "github.com",
        liveLink: "https://debutco.app/"

    },
    {
        id: 3,
        projectName: "Zeddie",
        description: ' web application for aimed for reading and learning for kids. It has a story book section where kids can read stories and learn new words. It has a quiz section where kids can test their knowledge. It has a game section where kids can play games and have fun. It has a profile section where kids can see their progress and achievements.',
        stacksused: ["React", "Bootstrap", "Firebase"],
        color: "success",
        images: [images.zeddie1, images.zeddie2, images.zeddie3],
        github: "github.com",
        liveLink: "https://zedie-4353b.web.app/"
    },
    {
        id: 4,
        projectName: "Fashion Magazine",
        description: "A website for a Fasion publication company.",
        stacksused: ["React", "Bootstrap", "Firebase", "React PDF"],
        color: "danger",
        images: [images.fashion1, images.fashion2, images.fashion3],
        github: "github.com",
        liveLink: "https://magazine-e683b.firebaseapp.com/"
    },
    {
        id: 5,
        projectName: "Sharre it ",
        description: "Web Application for sharing your items and belongings as a gift or as rental. ",
        stacksused: ["React", "Redux", "React Router", "React Bootstrap", "React PDF", "React Hook Form", "Swagger", "Rest API "],
        color: "warning",
        images: [images.fashion1, images.fashion2, images.fashion3],
        github: "github.com",
        liveLink: "live.com"
    }

]