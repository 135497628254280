import React, { useState, useContext } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane, Row, Col } from 'reactstrap'
// import About from './About'
import Contact from './Contact'
import Works from './Works'
import Personal from './Personal'
import { ThemeParent } from '../Context/ThemeContext'
import { BiMap } from 'react-icons/bi'
import { AiFillGithub } from 'react-icons/ai'
import { MdOutlineDarkMode } from 'react-icons/md'
import { me } from '../Assets/index'
import MotionContainer from '../Components/motionContainer'
export default function Parent() {
    const { theme, toggleTheme } = useContext(ThemeParent);
    const [activeTab, setActiveTab] = useState('1')
    const setAcriveTab = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab)
    }
    return (
        <div>
            <div className=' pt-5'>
                <Row className=' mx-auto border-bottom border-muted mb-5 pb-5' style={{ maxWidth: '50rem' }}>
                    <Col sm='3' md='3' lg='4' xl='3'  xs='12' className='d-flex justify-content-center'
                    >
                        <MotionContainer>
                            <img src={me}
                                alt='placeholder'
                                className='img-fluid  rounded-circle '
                                style={theme.workCard} />
                        </MotionContainer>
                    </Col>
                    <Col sm="9" md="8" lg="8" xl="9" xs='12'
                        className='text-start d-flex flex-column justify-content-center ps-5 pt-4'>
                        <h1 className='m-0' > Abraham T. Workie  </h1>
                        <h4> Software Engineer </h4>
                        <MotionContainer>
                            <h5 className=''>
                                <AiFillGithub className='me-2' size={35} />
                                <a href='https://github.com/AbrahamTerfie' target='abrahamterfie' rel="github" className='text-decoration-none text-secondary' >
                                    github/AbrahamTerfie
                                </a>
                            </h5>
                        </MotionContainer>
                        <p className='ps-2' >
                            <BiMap className='me-2' />
                            Maryland, USA
                        </p>

                    </Col>
                </Row>
            </div>
            <div>
                <Nav tabs className=' mx-auto d-flex justify-content-start align-items-center pb-3 flex-wrap'
                    style={{ maxWidth: '50rem' }}>
                    <NavItem>
                        <MotionContainer>
                            <NavLink
                                style={activeTab === '1' ? theme.activeTab : theme.notActiveTab}
                                onClick={() => { setAcriveTab('1') }}>
                                personal
                            </NavLink>
                        </MotionContainer>
                    </NavItem>
                    <NavItem>
                        <MotionContainer>
                            <NavLink
                                style={activeTab === '2' ? theme.activeTab : theme.notActiveTab}
                                onClick={() => { setAcriveTab('2') }}>
                                works
                            </NavLink>
                        </MotionContainer>
                    </NavItem>
                    <NavItem>
                        <MotionContainer>
                            <NavLink
                                style={activeTab === '3' ? theme.activeTab : theme.notActiveTab}
                                onClick={() => { setAcriveTab('3') }}>
                                contact
                            </NavLink>
                        </MotionContainer>
                    </NavItem>
                    {/* <NavItem>
                        <MotionContainer>
                            <NavLink
                                style={activeTab === '4' ? theme.activeTab : theme.notActiveTab}
                                onClick={() => { setAcriveTab('4') }}>
                                about
                            </NavLink>
                        </MotionContainer>
                    </NavItem> */}
                    <NavItem
                        className='ms-auto'
                    >
                        <MotionContainer>
                            <NavLink
                                style={theme.notActiveTab}
                                onClick={() => { toggleTheme() }}>
                                <MdOutlineDarkMode size={15} />
                            </NavLink>
                        </MotionContainer>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}
                    className=' mx-auto justify-content-center align-items-center mt-4 px-2'
                    style={{ maxWidth: '50rem' }}>
                    <TabPane tabId="1">
                        <Personal />
                    </TabPane>
                    <TabPane tabId="2">
                        <Works />
                    </TabPane>
                    {/* <TabPane tabId="4">
                        <About />
                    </TabPane> */}
                    <TabPane tabId="3">
                        <Contact />
                    </TabPane>
                </TabContent>
            </div>


        </div>
    )
}
