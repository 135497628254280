

// --background1: #fcfafaff;
// --background2: #c8d3d5ff;
// --text1: #6e8387ff;
// --text2: #a4b8c4ff;


export const light = {
    'background1': '#C8D3D5',
    // 'background1': '##C8D3D5',
    'background2': '##C8D3D5',
    'text1': '#6E8387',
    'text2': '##A4B8C4',
    "navLink": {
        color: 'yellow ',

    },

    "activeTab": {
        color: "#6E8387",
        borderRadius: "5px",
        background: "#C8D3D5",
        transition: 'all 1s ease-in-out',
        minWidth: '2em',
        boxShadow:
            "inset 3px 3px 7px #505455,\n            inset -3px -3px 7px #ffffff"
    },
    "notActiveTab": {
        color: "#6E8387",
        marginLeft: "10px",
        marginRight: "10px",
        borderRadius: "5px",
        minWidth: '2em',
        transition: 'all 1s ease-in-out',

        background: "linear-gradient(145deg, #b4bec0, #d6e2e4)",
        boxShadow: "3px 3px 7px #505455,\n             -3px -3px 7px #ffffff"


    },
    ".notActiveTab:hover": {
        color: "#A4B8C4",
        background: "#1D3557",
        boxShadow: "5px 5px 17px #162841,\n             -5px -5px 17px #24426d"

    },

    "workCard": {


        borderRadius: "34px",
        background: "#C8D3D5",
        boxShadow: "5px 5px 28px #505455,\n             -5px -5px 28px #ffffff"

    },

    "workCardDetails ": {
        borderRadius: "14px",
        background: "#C8D3D5",
        boxShadow:
            "inset 5px 5px 10px #505455,\n            inset -5px -5px 10px #ffffff",

    },

    "contactMeForm": {

        borderRadius: "10px",
        background: "linear-gradient(145deg, #b4bec0, #d6e2e4)",
        boxShadow: "5px 5px 10px #aab3b5,\n             -5px -5px 10px #e6f3f5"
    },
    "input": {
        color: "#6E8387",
        borderRadius: "5px",
        border: "none",
        background: "#C8D3D5",
        boxShadow:
            "inset 2px 2px 3px #505455,\n            inset -2px -2px 3px #ffffff"
    }





}
