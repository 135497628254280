import React, { useContext } from 'react';
import Parent from './Pages/Parent';
import './App.css';
import { ThemeParent } from './Context/ThemeContext';
import './Styles/light.css'

function Appcontent() { return (<Parent />) }

function App() {
  const { theme } = useContext(ThemeParent);
  return (
    <div
      style={{
        background: theme.background1,
        color: theme.text1,
        transition: 'all 0.5s ease',
        minHeight: '100vh',
        height: '100%',
      }
      } className='App'>
      <Appcontent />
    </div >

  );
}

export default App;
