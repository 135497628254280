import React, { useState, createContext } from 'react'
import { themes } from '../Styles';

export const ThemeParent = createContext({
    theme: themes.light,
    setTheme: (data: any) => { },
    toggleTheme: () => { }

});
export default function ThemeContext({ children }: any) {
    const [theme, setTheme] = useState(themes.light);
    return (
        <ThemeParent.Provider
            value={{
                theme,
                setTheme,
                toggleTheme: () => {
                    if (theme === themes.light) {
                        return setTheme(themes.dark)
                    } else if (theme === themes.dark) {
                        return setTheme(themes.light)
                    }

                }

            }}>
            {children}
        </ThemeParent.Provider >
    );
}