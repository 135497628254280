// --background1: #1d3557ff;
// --background2: #457b9dff;
// --text1: #f1faeeff;
// --text2: #a8dadcff;




export const dark = {
    'background1': '#1D3557',
    'background2': '#457B9D',
    'text2': '#F1FAEE',
    'text1': '#A8DADC',

    "navLink": {
        color: 'red',
    },

    "activeTab": {
        color: "#F1FAEE",

        borderRadius: "5px",
        background: "#1D3557",
        transition: 'all 1s ease-in-out',
        minWidth: '2em',
        boxShadow:
            "inset 3px 3px 7px #0c1523,\n            inset -3px -3px 7px #2e558b"
    },
    "notActiveTab": {
        color: "#F1FAEE",
        marginLeft: "10px",
        marginRight: "10px",
        borderRadius: "5px",
        transition: 'all 1s ease-in-out',
        minWidth: '2em',

        background: "linear-gradient(145deg, #1a304e, #1f395d)",
        boxShadow: "3px 3px 7px #0c1523,\n             -3px -3px 7px #2e558b"
    },
    ".notActiveTab:hover": {
        color: "#A8DADC",
        background: "#1D3557",
        boxShadow: "5px 5px 17px #162841,\n             -5px -5px 17px #24426d"

    },
    "workCard": {
        borderRadius: "34px",
        background: "#1D3557",
        boxShadow: "5px 5px 28px #0c1523,\n             -5px -5px 28px #2e558b"
    },
    "workCardDetails ": {
        borderRadius: "34px",
        background: "#1D3557",
        boxShadow:
            "inset 5px 5px 10px #0c1523,\n            inset -5px -5px 10px #2e558b"
    },
    "contactMeForm": {
        borderRadius: "10px",
        background: "linear-gradient(145deg, #1a304e, #1f395d)",
        boxShadow: "5px 5px 10px #192d4a,\n             -5px -5px 10px #213d64"
    },
    "input": {
        color: "#F1FAEE",
        borderRadius: "5px",
        border: "none",
        background: "#1D3557",
        boxShadow:
            "inset 5px 5px 15px #0c1523,\n            inset -5px -5px 15px #2e558b"
    }





}   