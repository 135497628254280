
import React, { useContext } from 'react'
import { FaRegHandSpock } from 'react-icons/fa'
import { AiFillFilePdf, AiFillGithub, AiFillLinkedin } from 'react-icons/ai'
import MotionContainer from '../Components/motionContainer'
import { Col, Row } from 'reactstrap'
import { ThemeParent } from '../Context/ThemeContext'


export default function Personal() {
    const { theme } = useContext(ThemeParent);
    return (
        <div>
            <div className='d-flex justify-content-start align-items-center mt-5 mb-5 px-4'>
                <FaRegHandSpock size={40} className="me-3" />
                <h3>Hello world</h3>
            </div>

            <div className='px-4'>
                <p
                    className='fs-6'
                >
                    I am a software engineer with a passion for building web applications. I've been working with web and mobile technologies for the past 4 years and have worked on a variety of projects, from small personal projects to large-scale enterprise applications. from beginning to end, from inception to deployment and maintenance, I have been involved in the entire development cycle.
                    I graduated from Addis Abeba Science and Technology University with a BSc in software engineering.
                    these are some of the technologies I have worked with:
                </p>
            </div>
            <div className='px-4'>
                <p> I graduated from Addis Abeba Science and Technology University with a BSc in software engineering. </p>
            </div>
            <div className='px-4' > <p> these are some of the technologies i have worked with: </p>
            </div>
            <div className='d-flex   flex-wrap px-5'>
                <span className='m-1 text-primary-emphasis bg-primary-subtle border border-primary-subtle  px-2 py-1 rounded-pill px-3 py-1'>
                    <MotionContainer>
                        Typescript
                    </MotionContainer>
                </span>
                <span className='m-1 text-secondary-emphasis bg-secondary-subtle border border-secondary-subtle  px-2 py-1 rounded-pill px-3 py-1'>
                    <MotionContainer>
                        JavaScript
                    </MotionContainer>
                </span>
                <span className='m-1 text-info-emphasis bg-info-subtle border border-info-subtle  px-2 py-1 rounded-pill px-3 py-1'>
                    <MotionContainer>
                        React
                    </MotionContainer>
                </span>
                <span className='m-1 text-danger-emphasis bg-danger-subtle border border-danger-subtle  px-2 py-1 rounded-pill px-3 py-1'>
                    <MotionContainer>
                        React-Native
                    </MotionContainer>
                </span>
                <span className='m-1 text-dark-emphasis bg-dark-subtle border border-dark-subtle  px-2 py-1 rounded-pill px-3 py-1'>
                    <MotionContainer>
                        Nodejs
                    </MotionContainer>
                </span>
                <span className='m-1 text-success-emphasis bg-success-subtle border border-success-subtle  px-2 py-1 rounded-pill px-3 py-1'>
                    <MotionContainer>
                        MongoDB
                    </MotionContainer>
                </span>
                <span className='m-1 text-warning-emphasis bg-warning-subtle border border-warning-subtle  px-2 py-1 rounded-pill px-3 py-1'>
                    <MotionContainer>
                        MySql
                    </MotionContainer>
                </span>
                <span className='m-1 text-tertary-emphasis bg-tertary-subtle border border-tertary-subtle  px-2 py-1 rounded-pill px-3 py-1'>
                    <MotionContainer>
                        PostgreSQl
                    </MotionContainer>
                </span>
                <span className='m-1 text-danger-emphasis bg-danger-subtle border border-danger-subtle  px-2 py-1 rounded-pill px-3 py-1'>
                    <MotionContainer>
                        GraphQl
                    </MotionContainer>
                </span>
                <span className='m-1 text-warning-emphasis bg-warning-subtle border border-warning-subtle  px-2 py-1 rounded-pill px-3 py-1'>
                    <MotionContainer>
                        Redux
                    </MotionContainer>
                </span>
                <span className='m-1 text-success-emphasis bg-success-subtle border border-success-subtle  px-2 py-1 rounded-pill px-3 py-1'>
                    <MotionContainer>
                        Express
                    </MotionContainer>
                </span>
                <span className='m-1 text-danger-emphasis bg-danger-subtle border border-danger-subtle  px-2 py-1 rounded-pill px-3 py-1'>
                    <MotionContainer>
                        HTML
                    </MotionContainer>
                </span>
                <span className='m-1 text-primary-emphasis bg-primary-subtle border border-primary-subtle  px-2 py-1 rounded-pill px-3 py-1'>
                    <MotionContainer>
                        CSS
                    </MotionContainer>
                </span>
                <span className='m-1 text-secondary-emphasis bg-secondary-subtle border border-secondary-subtle  px-2 py-1 rounded-pill px-3 py-1'>
                    <MotionContainer>
                        Java
                    </MotionContainer>
                </span>
                <span className='m-1 text-primary-emphasis bg-primary-subtle border border-primary-subtle  px-2 py-1 rounded-pill px-3 py-1'>
                    <MotionContainer>
                        C++
                    </MotionContainer>
                </span>
                <span className='m-1 text-info-emphasis bg-info-subtle border border-info-subtle  px-2 py-1 rounded-pill px-3 py-1'>
                    <MotionContainer>
                        Figma
                    </MotionContainer>
                </span>

            </div>
            <div>
                <Row className='d-flex flex-row justify-content-around align-items-center'>

                    <Col>
                        <MotionContainer>
                            <a href="https://www.linkedin.com/in/abraham-terfie/" target="_blank" rel="noreferrer"
                                style={theme.notActiveTab}

                                className='d-flex justify-content-start align-items-center mt-5 mb-3 px-4 bg-primary-subtle text-primary-emphasis rounded-2 w-100 mx-2 py-2'
                            >
                                <span><AiFillLinkedin size={30} className="me-3" />
                                    LinkedIn
                                </span>
                            </a>
                        </MotionContainer>
                    </Col>
                    <Col>
                        <MotionContainer>
                            <a href="https://github.com/AbrahamTerfie" target="_blank" rel="noreferrer"
                                style={theme.notActiveTab}

                                className='d-flex justify-content-start align-items-center mt-5 mb-3 px-4 w-100 bg-muted-subtle text-muted-emphasis rounded-2 py-2 w-100 mx-2'>
                                <span> <AiFillGithub size={30} className="me-3" />Github</span>
                            </a>
                        </MotionContainer></Col>

                </Row>
                <Row>
                    <MotionContainer>
                        <a href={require('../Assets/abrahamtworkie23.pdf')}
                            download="abrahamtworkie23.pdf"
                            style={theme.notActiveTab}

                            className='d-flex justify-content-start align-items-center  mb-5 px-4 w-100    text-success rounded-2 py-2 w-100 mx-2'>
                            <span> <AiFillFilePdf size={30} className="me-3" />Download CV</span>
                        </a>
                    </MotionContainer>
                </Row>

            </div>

        </div>
    )
}
