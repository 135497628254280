import React, { useContext } from 'react'
import { motion } from 'framer-motion'
import { Row, Col } from 'reactstrap'
import MotionContainer from '../Components/motionContainer'
import { ThemeParent } from '../Context/ThemeContext'
import { myData } from '../Data/Data'
import { AiFillGithub } from 'react-icons/ai'
import { FaGlobe } from 'react-icons/fa'

function WorksCard({ id, projectName, description, stacksused, images, github, liveLink, color }: {
    id: number, projectName: string, description: string, stacksused: string[], images: string[], github?: string, liveLink?: string, color: string
}

) {
    const { theme } = useContext(ThemeParent);
    return (
        <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: 0 }}
            whileHover={{ scale: 1.01 }}
            whileTap={{ scale: 1.04 }}
            transition={{ type: 'spring', stiffness: 100, damping: 40 }}
            style={theme.workCard} className='p-3 rounded mb-4  flex-wrap'>
            <Row className='p-3'>
                {images.map((img, index) => {
                    return (
                        <Col key={index} sm='12' md="6" lg="4" xl="4">
                            <MotionContainer>
                                <img src={img} alt='placeholder' className='img img-fluid shadow-lg' />
                            </MotionContainer>
                        </Col>
                    )
                })}
            </Row>
            <Row className='p-4 m-3' style={theme['workCardDetails ']} >
                <h1 style={{ color: theme.text2 }}> {projectName}  </h1>
                <p>{description} </p>
                <small> stacks used </small>
                <div className='d-flex flex-row flex-wrap'>

                    {stacksused.map((stack, index) => {
                        return (
                            <span key={index} className={`  m-1 text-${color}-emphasis bg-${color}-subtle border border-${color}-subtle  px-2 py-1 rounded-pill px-3 py-1`}>
                                <MotionContainer>
                                    {stack}
                                </MotionContainer>
                            </span>
                        )
                    })}
                </div>

                <div
                    className='d-flex flex-row flex-wrap justify-content-start align-items-center mt-3'
                >
                    <AiFillGithub size={25} className='m-2 ' />
                    <a href={github} target={github} rel="github"
                        className='me-3 text-secondary text-decoration-none fs-5'>
                        {github} </a>
                    <FaGlobe size={25} className='m-2' />
                    <a href={liveLink} target={liveLink} rel="liveLink"
                        className='me-3 text-secondary text-decoration-none fs-5'

                    >  {liveLink} </a>
                </div>
            </Row>

        </motion.div>
    )
}



export default function Works() {
    return (
        <div>
            {myData.map((data, index) => {
                return (
                    <WorksCard key={index}
                        color={data.color}
                        id={data.id}
                        projectName={data.projectName}
                        stacksused={data.stacksused}
                        images={data.images}
                        github={data.github}
                        liveLink={data.liveLink}
                        description={data.description} />
                )
            })}
        </div>
    )
}
